<template>
  <div class='px-2 lg:px-0 pt-4 lg:pt-12'>
    <div class='flex flex-wrap lg:flex-row mb-8 gap-x-2 gap-y-1'>
      <select class='rounded-md border py-2 px-4' 
        v-model='sessionDetailsListSelectedCatogory'>
          <option v-for="category in selectableCategories"
            :key='`category-${category}`'
            :value='category'>
            {{ category }}
          </option>
      </select>
      <select v-if='showSessionDatesFilter'
        class='rounded-md border py-2 px-4' 
        v-model='sessionDetailsListSelectedDate'>
          <option v-for="sessionDate in selectableDates"
            :key='`session-date-${sessionDate}`'
            :value='sessionDate'>
            {{ sessionDate }}
          </option>
      </select>
      <select v-if='showSessionRoomsFilter'
        class='rounded-md border py-2 px-4' 
        v-model='sessionDetailsListSelectedRoom'>
          <option v-for="sessionRoom in selectableRooms"
            :key='`session-date-${sessionRoom}`'
            :value='sessionRoom'>
            {{ sessionRoom.split('||')[0] }}
          </option>
      </select>
    </div>
    <session-details-full
      v-for='sessionDetail in sessionDetailsListAllCategoriesWithExceptions'
      :key='`sessionDetail-${sessionDetail.id}`'
      :session='sessionDetail'
      :repeatedComponent='true'
      class='mb-12 lg:mb-16 pb-4' />
    <div v-if='emptySessionDetailsList'
      class='px-16 py-24 text-center bg-gray-100 rounded-md max-w-4xl'>
      No sessions found. Please update your filter settings.
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import SessionDetailsFull from '@/components/sessions/SessionDetailsFull.vue'
import cloneDeep          from 'lodash/cloneDeep'
export default {
  name: 'ProgramDetails',
  components: {
    SessionDetailsFull,
  },
  props: [
    'needToBeLoggedIn'
  ],
  data () {
    return {
      filterList: '',
      sessionDetailsListSelectedCatogory: '',
      sessionDetailsListSelectedDate: '',
      sessionDetailsListSelectedRoom: '',
    }
  },
  watch: {
    '$route.query': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.sessionDetailsListSelectedCatogory = newVal['categories'] ? newVal['categories'] : this.selectableCategories[0]
          this.sessionDetailsListSelectedDate = newVal['sessionDate'] ? newVal['sessionDate'] : this.selectableDates[0]
          this.sessionDetailsListSelectedRoom = newVal['sessionRoom'] ? newVal['sessionRoom'] : this.selectableRooms[0]
        }
      },
      deep: true,
      immediate: true
    },
    'queryParams': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          let newQueryParams = cloneDeep(newVal)
          this.$router.push({query: newQueryParams}).catch(() => {})
          this.getSessionDetailsList(newQueryParams)
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapState('sessions', [
      'sessionDetailsList',
    ]),
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('filters', [
      'showingContentsFilterListByFilterName',
    ]),
    queryParams () {
      let categoryParam = (this.sessionDetailsListSelectedCatogory === 'All Categories') ? '' : this.sessionDetailsListSelectedCatogory
      let dateParam     = (this.sessionDetailsListSelectedDate === 'All Dates') ? '' : this.sessionDetailsListSelectedDate
      let roomParam     = (this.sessionDetailsListSelectedRoom === 'All Rooms') ? '' : this.sessionDetailsListSelectedRoom
      return {
        categories: categoryParam,
        sessionDate: dateParam,
        sessionRoom: roomParam
      }
    },
    sessionDetailsListAllCategoriesWithExceptions () {
      return this.sessionDetailsList.filter(session => {
        return session.category !== 'break' && session.sessionType !== 'break' && !session.configurations.hideSessionInProgramDetails
      })
    },
    emptySessionDetailsList () {
      return this.sessionDetailsListAllCategoriesWithExceptions.length === 0
    },
    selectableCategories () {
      let list = this.showingContentsFilterListByFilterName('session_detail', 'category')
      return ['All Categories', ...list]
    },
    selectableDates () {
      let list =  this.showingContentsFilterListByFilterName('session_detail', 'dates')
      return ['All Dates', ...list]
    },
    selectableRooms () {
      let list =  this.showingContentsFilterListByFilterName('session_detail', 'rooms')
      return ['All Rooms', ...list]
    },
    showSessionDatesFilter () {
      return this.selectableDates.length > 1
    },
    showSessionRoomsFilter () {
      return this.selectableRooms.length > 1
    },
  },
  methods: {
    ...mapActions('sessions', [
      'getSessionDetailsList',
    ]),
    ...mapActions('filters', [
      'getFilters',
    ]),
    ...mapActions('users', [
      'checkTokenStatus',
    ]),
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (!this.hasValidToken && this.needToBeLoggedIn) {
        this.$router.replace({name: 'Login', query: {redirect_route: 'ProgramDetails'} })
      }
    })
  },
  mounted () {
    this.getFilters().then(() => {
    })
  }
}
</script>

